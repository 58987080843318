import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RxjsComponent } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { WizardService } from '../wizard.service';

@Component({
    selector: 'app-wizard-step-card',
    templateUrl: './wizard-step-card.component.html',
})
export class WizardStepCardComponent extends RxjsComponent implements OnInit {
    @Input() public header = '';
    @Input() public formGroup: UntypedFormGroup;
    @Input() public hasPrevious: boolean;
    @Input() public hasNext: boolean;
    @Input() public skipStep: boolean;
    @Input() public submitTranslationLabel = 'NEXT';
    @Input() public loading = false;
    @Input() public canPrevious = true;
    @Input() public canNext = true;
    @Input() public showStepIndication = true;
    @Input() public forceShowSubmit = false;

    @Output() public previous = new EventEmitter();
    @Output() public next = new EventEmitter();
    @Output() public skip = new EventEmitter();

    public current: number;
    public total: number;

    constructor(public readonly wizardService: WizardService) {
        super();
    }

    public ngOnInit(): void {
        this.total = this.wizardService.getStepsForStepper().length;
        this.current = this.wizardService.getCurrentStepperIndex() + 1;
        this.hasPrevious = this.canPrevious && (this.hasPrevious || this.wizardService.getCurrentStepIndex() > 0);
        this.hasNext = this.canNext && (this.hasNext || this.current < this.wizardService.steps.length);

        this.wizardService.loading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => (this.loading = loading));
    }

    public onSubmit(event): void {
        this.next.emit(event);
    }

    public onPrevious(): void {
        this.wizardService.goToPrevious();
    }

    public onSkip(): void {
        this.skip.emit();
    }
}
