<app-card-container class="u-flex-row u-flex-justify-content-center">
    <div class="u-container">
        <app-loading-container [loading]="loading">
            <app-ui-card [header]="header" variant="h3">
                <p *ngIf="(!!current || !!total) && showStepIndication" class="u-color-muted" card-header>
                    {{
                        'STEP_INDICATION'
                            | translate
                                : {
                                      start: current,
                                      end: total
                                  }
                    }}
                </p>
                <ng-container card-header-right><ng-content select="[card-header-right]"></ng-content></ng-container>
                <ng-container card-header><ng-content select="[card-header]"></ng-content></ng-container>
                <form class="u-flex-column u-flex-align-items-start u-w100p u-margin-top-triple" (ngSubmit)="onSubmit($event)">
                    <ng-content></ng-content>
                    <app-previous-submit
                        [submitTranslationLabel]="submitTranslationLabel"
                        [showPrevious]="hasPrevious"
                        (previous)="onPrevious()"
                        (skipStep)="onSkip()"
                        [disabled]="loading"
                        [showSkipStep]="skipStep"
                        [loading]="loading"
                        [showSubmit]="hasNext || forceShowSubmit"
                    ></app-previous-submit>
                </form>
            </app-ui-card>
        </app-loading-container>
    </div>
</app-card-container>
