import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormControlGroups } from '@app/mortgage/enums/form-control-groups.enum';
import { RxjsService } from '@smooved/core';
import { Observable, of } from 'rxjs';
import { WizardStep } from './wizard-step.interface';

@Injectable()
export abstract class WizardService extends RxjsService {
    public steps: WizardStep[] = [];
    public currentStep: WizardStep;
    public form: UntypedFormGroup;
    public baseRouting: string[];
    public currentFormControlGroupsToValidate: FormControlGroups[];

    public stepsToExcludeFromStepper: WizardStep[] = [];

    public abstract loading$: Observable<boolean>;

    constructor(protected readonly router: Router, protected location: Location) {
        super();
    }

    public setCurrentStep(step: WizardStep, formGroupsToValidate: FormControlGroups[] = []): void {
        if (this.steps.includes(step)) {
            this.currentStep = step;
        }
        this.currentFormControlGroupsToValidate = formGroupsToValidate;
    }

    public getStepIndex(wizardStep: WizardStep): number {
        return this.steps.findIndex((step) => step === wizardStep);
    }

    public setSteps(steps: WizardStep[], start?: WizardStep): void {
        this.steps = steps;
        this.currentStep = start ?? steps[0];
    }

    public setBaseRoute(route: string[]): void {
        this.baseRouting = route;
    }

    public getStepsForStepper(): WizardStep[] {
        return this.steps.filter((step) => !this.stepsToExcludeFromStepper.includes(step));
    }

    public getCurrentStepperIndex(): number {
        return this.getStepsForStepper().findIndex((step) => step === this.currentStep);
    }

    public getCurrentStepIndex(): number {
        return this.getStepIndex(this.currentStep);
    }

    public partialFormValidation(groups: FormControlGroups[]): boolean {
        if (!groups?.length) return true;
        return groups.every((group) => this.form.get(group).valid);
    }

    public getPageAt(index: number): string[] {
        if (!this.partialFormValidation(this.currentFormControlGroupsToValidate)) return;
        return [...this.baseRouting, this.steps[index]];
    }

    public getPage(wizardStep: WizardStep): string[] {
        const index = this.steps.findIndex((step) => step === wizardStep);
        return this.getPageAt(index);
    }

    public getNextPage(steps = 1): string[] {
        if (!this.partialFormValidation(this.currentFormControlGroupsToValidate)) return;
        return [...this.baseRouting, this.steps[this.getCurrentStepIndex() + steps]];
    }

    public getPreviousPage(steps = 1): string[] {
        return [...this.baseRouting, this.steps[this.getCurrentStepIndex() - steps]];
    }

    public skip(steps = 1, forward: boolean = true): void {
        const nextPage = forward ? this.getNextPage(steps + 1) : this.getPreviousPage(steps + 1);
        void this.router.navigate(nextPage);
    }

    public goToNext(steps = 1): void {
        const nextPage = this.getNextPage(steps);
        void this.router.navigate(nextPage);
    }

    public goToPrevious(steps = 1): void {
        const previousPage = this.getPreviousPage(steps);
        void this.router.navigate(previousPage);
    }

    public goToStep(wizardStep: WizardStep): void {
        const stepRoute = this.getPage(wizardStep);
        void this.router.navigate(stepRoute);
    }

    // Placeholder function for wizardService submit logic
    public onSubmit(): void {
        /** */
    }

    // Placeholder function for wizardService initiation logic
    public initialize(): void {
        /** */
    }

    // Placeholder function for wizardService reset logic
    public reset(): void {
        /** */
    }

    // Placeholder function for canActivate
    public canActivate(): Observable<boolean> {
        return of(true);
    }
}
